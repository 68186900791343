<template>
  <div>
    <!-- 头部横幅 -->
    <bigbg
      url="static/srbanner/yOzWU3FW.webp"
      bigtext="电子巡更"
      smalltext="结合博物馆场地情况及管理要求，提供基于智能设备的巡更一体解决方案"
    ></bigbg>
    <!-- 2.智能巡更系统 -->
    <div class="twobg">
      <div class="twotext">智能巡更系统</div>
      <div class="imgbox">
        <div class="img"></div>
        <div class="textimg">
          <div class="text">简单、智能、可视化</div>
          <div class="imgsmall">
            <div class="imgtexbox">
              <div class="onebox">
                <div class="photo1"></div>
                <div class="photo2"></div>
                <div class="photo3"></div>
                <div class="photo4"></div>
                <div class="photo5"></div>
              </div>
              <div class="twobox">
                <div class="photo6"></div>

                <div class="photo7"></div>
                <div class="photo8"></div>
                <div class="photo9"></div>
                <div class="photo10"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 3.匠心智造 -->
    <div class="threebox">
      <div class="text">匠心智造</div>
      <div class="threeimgbox">
        <div class="objbox">
          <div class="imgphoto"></div>
          <div class="textwenzi">
            <div class="bigtext">巡更棒BP-2012S</div>
            <div class="smalltext">
              坚固抗摔：金属内胆，弹性橡胶外壳，柔性硅胶内衬，电路板环氧固化；完全防水：完全密封设计，电路板防水处理，可在水中使用；防强电击：能抵御电压警棍电击破坏；自动感应：无需按键，无需接触，自动探测读卡
            </div>
          </div>
        </div>
        <div class="objbox1">
          <div class="imgphoto"></div>
          <div class="textwenzi">
            <div class="bigtext">人员卡</div>
            <div class="smalltext">识别每个巡逻人员</div>
          </div>
        </div>

        <div class="objbox2">
          <div class="imgphoto"></div>
          <div class="textwenzi">
            <div class="bigtext">巡更点</div>
            <div class="smalltext">
              巡更点可以明确的标注巡逻员需要到位巡逻的位置
            </div>
          </div>
        </div>
        <div class="objbox3">
          <div class="imgphoto"></div>
          <div class="textwenzi">
            <div class="bigtext">无线通讯座</div>
            <div class="smalltext">
              数据上传方法多样，适应不同传输要求：通过USB线直接上传数据到电脑
            </div>
          </div>
        </div>
        <div class="objbox4">
          <div class="imgphoto"></div>
          <div class="textwenzi">
            <div class="bigtext">标识牌</div>
            <div class="smalltext">
              1. 标识信息钮位置<br />
              2. 保护墙内信息钮<br />
              3. 体现巡更管理系统的工程形象
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 4 350px    最后 -->
   
    <!-- 引入头部底部组件 -->
    <Header></Header>
    <Footer></Footer>
  </div>
</template>
<script>
import bigbg from "@/views/srdemo/component/bigbg.vue";
// 导入头部底部组件
//引入头部组件
import Header from "@/components/Header.vue";
// 引入底部组件
import Footer from "@/components/Footer.vue";
export default {
  name: "filmxun",
  components: {
    bigbg,
    Header,
    Footer,
  },
};
</script>
<style lang="scss" scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
// 2
.twobg {
  height: 636px;
  .twotext {
    margin: 80px auto 60px;
    font-size: 40px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 56px;
    letter-spacing: 1px;
  }
  .imgbox {
    margin: 0 auto;
    width: 1200px;
    height: 340px;
    display: flex;
    .img {
      width: 587px;
      height: 340px;
      background: url("./photo/5rioHXYp.webp") no-repeat center;
      background-size: 100% 100%;
    }
    .textimg {
      background: #ffffff;
      box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
      border-radius: 6px;
      flex: 1;
      .text {
        margin-top: 44px;
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 28px;
        margin-bottom: 36px;
      }
      .imgsmall {
        .imgtexbox {
          .onebox {
            display: flex;
            flex: 20%;
            flex-wrap: wrap;
            justify-content: space-between;
            flex-direction: row;
            margin-bottom: 36px;
            padding-left: 86px;
            padding-right: 86px;
          }
          .twobox {
            display: flex;
            flex: 20%;
            flex-wrap: wrap;
            justify-content: space-between;
            flex-direction: row;
            padding-left: 86px;
            padding-right: 86px;
          }
          .photo1 {
            width: 56px;
            height: 76px;
            background: url("./photo/card1.png") no-repeat center;
            background-size: 100% 100%;
          }
          .photo1 {
            width: 56px;
            height: 76px;
            background: url("./photo/card1.png") no-repeat center;
            background-size: 100% 100%;
          }
          .photo2 {
            width: 56px;
            height: 76px;
            background: url("./photo/card2.png") no-repeat center;
            background-size: 100% 100%;
          }
          .photo3 {
            width: 56px;
            height: 76px;
            background: url("./photo/card3.png") no-repeat center;
            background-size: 100% 100%;
          }
          .photo4 {
            width: 56px;
            height: 76px;
            background: url("./photo/card4.png") no-repeat center;
            background-size: 100% 100%;
          }
          .photo5 {
            width: 56px;
            height: 76px;
            background: url("./photo/card5.png") no-repeat center;
            background-size: 100% 100%;
          }
          .photo6 {
            width: 56px;
            height: 76px;
            background: url("./photo/card6.png") no-repeat center;
            background-size: 100% 100%;
          }
          .photo7 {
            width: 56px;
            height: 76px;
            background: url("./photo/card7.png") no-repeat center;
            background-size: 100% 100%;
          }
          .photo8 {
            width: 56px;
            height: 76px;
            background: url("./photo/card8.png") no-repeat center;
            background-size: 100% 100%;
          }
          .photo9 {
            width: 56px;
            height: 76px;
            background: url("./photo/card9.png") no-repeat center;
            background-size: 100% 100%;
          }
          .photo10 {
            width: 56px;
            height: 76px;
            background: url("./photo/card10.png") no-repeat center;
            background-size: 100% 100%;
          }
        }
      }
    }
  }
}
//3
.threebox {
  width: 1920px;
  height: 992px;
  background: #f6f8fb;
  padding-top: 80px;
  .text {
    font-size: 40px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 56px;
    letter-spacing: 1px;
  }
  .threeimgbox {
    width: 1200px;
    margin: 0 auto;
    margin-top: 60px;
    position: relative;
    .objbox {
      width: 585px;
      height: 212px;
      display: flex;
      padding: 24px;
      background-color: #fff;
      box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
      border-radius: 2px;
      margin-bottom: 30px;
      .imgphoto {
        width: 226px;
        height: 164px;
        background: url("./photo/11.png") no-repeat center;
        background-size: 100% 100%;
      }
      .textwenzi {
        margin-left: 24px;
        flex: 1;
        .bigtext {
          font-size: 20px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          text-align: left;
          margin-bottom: 16px;
        }
        .smalltext {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          text-align: left;
        }
      }
    }
    .objbox1 {
      position: absolute;
      right: 0;
      top: 0;
      width: 585px;
      height: 212px;
      display: flex;
      padding: 24px;
      background-color: #fff;
      box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
      border-radius: 2px;
      margin-bottom: 30px;
      .imgphoto {
        width: 226px;
        height: 164px;
        background: url("./photo/22.png") no-repeat center;
        background-size: 100% 100%;
      }
      .textwenzi {
        margin-left: 24px;
        flex: 1;
        .bigtext {
          font-size: 20px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          text-align: left;
          margin-bottom: 16px;
        }
        .smalltext {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          text-align: left;
        }
      }
    }
    .objbox2 {
      width: 585px;
      height: 212px;
      display: flex;
      padding: 24px;
      background-color: #fff;
      box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
      border-radius: 2px;
      margin-bottom: 30px;
      .imgphoto {
        width: 226px;
        height: 164px;
        background: url("./photo/33.png") no-repeat center;
        background-size: 100% 100%;
      }
      .textwenzi {
        margin-left: 24px;
        flex: 1;
        .bigtext {
          font-size: 20px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          text-align: left;
          margin-bottom: 16px;
        }
        .smalltext {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          text-align: left;
        }
      }
    }
    .objbox3 {
      position: absolute;
      right: 0;
      top: 243px;
      width: 585px;
      height: 212px;
      display: flex;
      padding: 24px;
      background-color: #fff;
      box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
      border-radius: 2px;
      .imgphoto {
        width: 226px;
        height: 164px;
        background: url("./photo/44.png") no-repeat center;
        background-size: 100% 100%;
      }
      .textwenzi {
        margin-left: 24px;
        flex: 1;
        .bigtext {
          font-size: 20px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          text-align: left;
          margin-bottom: 16px;
        }
        .smalltext {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          text-align: left;
        }
      }
    }
    .objbox4 {
      width: 585px;
      height: 212px;
      display: flex;
      padding: 24px;
      background-color: #fff;
      box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
      border-radius: 2px;
      .imgphoto {
        width: 226px;
        height: 164px;
        background: url("./photo/55.png") no-repeat center;
        background-size: 100% 100%;
      }
      .textwenzi {
        margin-left: 24px;
        flex: 1;
        .bigtext {
          font-size: 20px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          text-align: left;
          margin-bottom: 16px;
        }
        .smalltext {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          text-align: left;
        }
      }
    }
  }
}
//4
</style>